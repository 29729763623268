<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Next Maintenance</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered table-hover">
                  <thead>
                  <tr>
                    <th>Equipment Name</th>
                    <th>Type</th>
                    <th class="text-center">Current Hours/KM</th>
                    <th class="text-center">Engine Oil Change</th>
                    <th class="text-center">Pump Oil Change</th>
                    <th class="text-center">Major Service</th>
                    <th class="text-center">Transmission Flush</th>
                    <th class="text-center">Inspection</th>
                    <th>Tech Name</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="equipment in equipments"
                      :key="'equipment_'+equipment.id">
                    <td>{{ equipment.name }}</td>
                    <td class="text-capitalize">{{ equipment.type }}</td>
                    <td class="text-center">{{ equipment.current }}</td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Engine Oil Change')]">
                        {{ getNextServiceDate(equipment, 'Engine Oil Change') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Pump Oil Change')]">
                        {{ getNextServiceDate(equipment, 'Pump Oil Change') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Major Service')]">
                        {{ getNextServiceDate(equipment, 'Major Service') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Transmission Flush')]">
                        {{ getNextServiceDate(equipment, 'Transmission Flush') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Inspection')]">
                        {{ getNextServiceDate(equipment, 'Inspection') }}
                      </span>
                    </td>
                    <td>
                      {{ equipment.maintenances.length ? equipment.maintenances[0].user?.name : '' }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      equipments: []
    }
  },
  created() {
    this.axios.get('/next/equipment_maintenances')
        .then(res => {
          this.equipments = res.data.data;
        });
  },
  methods: {
    getNextServiceDate(equipment, type) {
      const maintenance = equipment.maintenances.find(maintenance => maintenance.sub_service === type);

      if (maintenance) {
        return maintenance.next_service_date;
      }

      return '';
    },
    getBadgeColor(equipment, type) {
      const maintenance = equipment.maintenances.find(maintenance => maintenance.sub_service === type);

      if (maintenance) {
        if (maintenance.next_service_day_due <= 5) {
          return 'badge-danger';
        } else if (maintenance.next_service_day_due <= 15) {
          return 'badge-warning';
        }
      }

      return 'badge-success';
    }
  }
}
</script>